import { Component, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { CommonModule, NgIf } from '@angular/common';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule,NgIf],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  encapsulation:ViewEncapsulation.ShadowDom
})
export class SpinnerComponent {
  constructor(public loader:LoaderService){}
}
