import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppService } from './shared/services/app.service';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgIf,SpinnerComponent]
})
export class AppComponent implements OnInit {
  constructor
    (
      private appService: AppService
    ) {
  }

  ngOnInit(): void {
    this.loadCurrentUser();
  }

  loadCurrentUser() {
    try {
        const token = localStorage.getItem('token') ?? "";
        if (token) this.appService.loadCurrentUser(token).subscribe();      
    }catch{
      
    }
  }
}
