// Angular modules
import { DatePipe }               from '@angular/common';
import { HTTP_INTERCEPTORS, withFetch }              from '@angular/common/http';
import { withInterceptorsFromDi } from '@angular/common/http';
import { provideHttpClient }      from '@angular/common/http';
import { HttpClient }             from '@angular/common/http';
import { ApplicationConfig }      from '@angular/core';
import { importProvidersFrom }    from '@angular/core';
import { BrowserModule }          from '@angular/platform-browser';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations }      from '@angular/platform-browser/animations';
import { provideRouter }          from '@angular/router';
import { withInMemoryScrolling }  from '@angular/router';
import { withRouterConfig }       from '@angular/router';
import { AppService }         from'../app/shared/services/app.service';
import { routes }                 from './app.routes';
import { ChangeRequestService } from './pages/change-request/changeRequestService';
import { HomeService } from './pages/home/homeService';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';

export function createTranslateLoader(http : HttpClient)
{
}

export const appConfig : ApplicationConfig = {
  providers : [

    // Routing
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation : 'reload',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration : 'enabled'
      }),
    ),

    importProvidersFrom(
      BrowserModule,
    ),

    AppService,
    ChangeRequestService,
    HomeService,
     { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
    ,
    // Pipes
    DatePipe,

    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAnimations(),
    provideClientHydration(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:LoaderInterceptor,
      multi:true
    }
  ]
};