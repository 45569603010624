// Angular modules
import { Routes } from '@angular/router';
import { LayoutHeaderComponent } from './shared/components/layouts/layout-header/layout-header.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.routes').then((m) => m.routes),
  },

  {
    path: 'changeRequest',
    component: LayoutHeaderComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/change-request/change-request.module')
          .then(m => m.ChangeRequestModule)
      }
    ]
  },

  {
    path: 'home',
    component: LayoutHeaderComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module')
          .then(m => m.HomeModule)
      }
    ]
  },

  {
    path: 'layout',
    loadComponent: () =>
      import('./shared/components/layouts/page-layout/page-layout.component').then((m) => m.PageLayoutComponent),
  },

  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  {path:"**", redirectTo:'/home'}
  
];
